import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div style={{minHeight: '60px'}}>
        <Navbar expand="md">
          <NavbarBrand style={{color: 'green', fontSize: '1.5em', WebkitTextStrokeColor: 'black', WebkitTextStrokeWidth: '0.3px'}} href="/">OHDSERVERS.NET</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/* <NavItem>
                <NavLink className="navbarhoover" href="/discord">Discord Bot</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink className="navbarhoover" href="/serversetup">ServerSetup</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink className="navbarhoover" href="/events">Events</NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink className="navbarhoover" href="/videos">Videos</NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink  className="navbarhoover" href="/global">Stats</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink className="navbarhoover" href="/about">About</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="navbarhoover">
                  Links
                </DropdownToggle>
                <DropdownMenu style={{background: 'grey', color: 'white'}} end>
                  <DropdownItem style={{background: 'grey', color: 'white'}}>
                  <NavLink className="navbarhoover" href="https://operationharshdoorstop.com">O:HD Website</NavLink>
                  </DropdownItem>
                  <DropdownItem style={{background: 'grey', color: 'white'}}>
                  <NavLink  className="navbarhoover" href="https://store.steampowered.com/app/736590/Operation_Harsh_Doorstop/">O:HD for Free here</NavLink>
                  </DropdownItem>
                  <DropdownItem style={{background: 'grey', color: 'white'}}>
                  <NavLink  className="navbarhoover" href="https://ohd.tug.gg/servers">ohd.tug.gg/servers</NavLink>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem style={{background: 'grey', color: 'white'}}>
                    Close
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}