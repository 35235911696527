import React, { useState, useEffect } from 'react';
import Ticker from 'react-ticker'

function TickerList(props) {
    const [tickerList, setTickerList] = useState([]);

    const fetchData = async () => {
        fetch('https://api.ohdservers.net/api/tickers')
        //fetch('http://localhost:8080/api/tickers')
          .then(response => response.json())
          .then(resultData => {
            //console.log(resultData);
            if (resultData.length > 0) {
              setTickerList(resultData);
              //console.log(resultData);
            } else {
              setTickerList([]);
              //console.log(resultData);
            }
           
            // let tl = [];
            // for(let s of resultData.split(",")){
            //   tl.push(s.value);
            // };
            // console.log(tl);
            // setTickerList(tl);
          })
          .catch(error => ({ "state": "error", "msg": "Ticker info could not be retrieved." }))
    }
    
    useEffect(() => {
    fetchData();
    }, [])

    useEffect(() => {
    const interval = setInterval(() => {
        fetchData();
    }, 60000);
    return () => clearInterval(interval);
    }, [])
    //console.log(tickerList);
    return (
          <footer className="fixed-bottom">
            <Ticker speed={8} className="ticker" mode="smooth">
              {({ index }) => (
                <>
                  {tickerList && tickerList.length > 0 ? (
                    tickerList.map((p,index) => (
                        <span className="promo" key={index}>{p.value}</span>
                    ))
                ) : (
                      <span className="promo">Welcome to ohdservers.net</span>
                )}
                </>
              )}
            </Ticker>



            {/* <Ticker>
              {({ index }) => (
                <span className="promo">
                   { tickerList.length > 0 ? (
                    <><span className="promo" key={0}>{tickerList[0].value}</span></> 
                    ) : <></> 
                   }
                   { tickerList.length > 1 ? (
                    <><span className="promo" key={1}>{tickerList[1].value}</span></> 
                    ) : <></> 
                   }
                   { tickerList.length > 2 ? (
                    <><span className="promo" key={2}>{tickerList[2].value}</span></> 
                    ) : <></> 
                   }

                  { tickerList.length > 3 ? (
                    <><span className="promo" key={3}>{tickerList[3].value}</span></> 
                    ) : <></> 
                   }

                   { tickerList.length > 4 ? (
                    <><span className="promo" key={4}>{tickerList[4].value}</span></> 
                    ) : {}
                   }

                </span>
              )}
            </Ticker> */}
          </footer>
    )


}

export default TickerList;



 