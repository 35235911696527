import React, { useState, useEffect } from 'react';
import { InputGroup, Button, Input } from 'reactstrap';

import { FaWrench } from 'react-icons/fa';
import { FaRegUser } from 'react-icons/fa';
import { MdManageSearch } from 'react-icons/md';
import { MdOutlineWbSunny } from "react-icons/md";
import { BsFillMoonStarsFill } from "react-icons/bs";
import PacmanLoader from "react-spinners/PacmanLoader";
import TickerList from './components/TickerList';
import TitleCard from './components/TitleCard';

// import useSound from 'use-sound';
// import plop from './sounds/space-click.wav';

function V6(props) {
    const [serverconfig, setserverconfig] = useState('');
    const [loadinganim, setloadinganim] = useState({ "loading": true });
    const [foundServers, setFoundServers] = useState('');
    const [darkMode, setDarkMode] = useState(true);
  
    // const [play] = useSound(plop);

    const fetchData = async () => {
      setloadinganim({ "loading": true })
      fetch('https://api.ohdservers.net/api/allservers')
      //fetch('http://localhost:8080/api/allservers')
        .then(response => response.json())
        .then(resultData => {
          if (resultData.state !== "error") {
            // console.log(resultData);
            setserverconfig(resultData);
            setFoundServers(resultData);
          }
          // else {
          //   console.log("Error state detected. Not refreshing data.")
          // }
          setSearch('');
          setloadinganim({ "loading": false })
        })
        .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved. " + error }))
    }

    async function findServerName(server) {
      fetch(`https://api.ohdservers.net/api/findservername?q=${server}`)
      //fetch('http://localhost:8080/api/allservers')
        .then(response => response.json())
        .then(resultData => {
          //setServerFind(resultData);

          setFoundServers(resultData);
          //console.log(resultData);
     })
        .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved. " + error }))
    }
  
    useEffect(() => {
      fetchData();
    }, [])
  
    useEffect(() => {
      const interval = setInterval(() => {
        fetchData();
      }, 60000);
      return () => clearInterval(interval);
    }, [])

    // the value of the search field 
    const [search, setSearch] = useState('');  
    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
          const results = serverconfig.filter((s) => {
            return s.name.toLowerCase().includes(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
          });
          if (keyword.length <= 4) {
            setFoundServers(results);
          } else {
            if(keyword.length <= 5) {
              findServerName(keyword);
            }
          }

          //findServerName(keyword);
        } else {
          setFoundServers(serverconfig);
        }
        setSearch(keyword);
      
      };

      const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "black",
      };
    //console.log(serverconfig);
  return (
    <>
      <div className={(!darkMode) ? ("overallbg") : ("overallbg_dark")}>
         
            <InputGroup>
                <a href="/empty" target="_self">< MdManageSearch size="30px" /></a>
                {(!darkMode) ? 
                  (< BsFillMoonStarsFill size="20px" onClick={() => {
                    setDarkMode(true);
                  }}/>) : 
                  (< MdOutlineWbSunny color="white" size="20px" onClick={() => {
                    setDarkMode(false);
                  }}  />)
                }
                {(!darkMode) ? 
                  ( <Input
                    className="inputSearch"
                    bsSize="sm"
                    type="search-light"
                    value={search}
                    onChange={filter}
                    placeholder="Find your server"
                    />
                  ) : 
                  ( <Input
                    className="inputSearch_dark"
                    bsSize="sm"
                    type="search-dark"
                    value={search}
                    onChange={filter}
                    />
                  )
                }
               
               
                {/* <a className="d-none d-md-block" href="/discord"><Button size="sm" outline color="warning"><FaDiscord size="20px" /></Button></a> */}
                <a className="d-none d-md-block" href="/serversetup"><Button size="sm" outline color="warning"><FaWrench size="20px" /></Button></a>
                {/* <a className="d-none d-md-block" href="/events"><Button size="sm" outline color="warning"><FaRegCalendarAlt size="20px" /></Button></a> */}
                {/* <a className="d-none d-md-block" href="/videos"><Button size="sm" outline color="warning"><FaFilm size="20px" /></Button></a> */}
                {/* <a className="d-none d-md-block" href="/global"><Button size="sm" outline color="warning"><FaRegChartBar size="20px" /></Button></a> */}
                <a className="d-none d-md-block" href="/about"><Button size="sm" outline color="warning"><FaRegUser size="20px" /></Button></a>
                <a className="d-none d-md-block" href="https://store.steampowered.com/app/736590/Operation_Harsh_Doorstop/" target="_new"><Button size="sm" outline color="success">OHD Free</Button></a>

                {/* <Button className="d-none d-md-block" style={{backgroundColor: 'rgba(0, 0, 0, 0.4)', color:"#FFF",boxShadow: "0px 5px 3px rgba(46, 46, 46, 0)"}}>O:HD <a href="https://store.steampowered.com/app/736590/Operation_Harsh_Doorstop/"> Free </a>here</Button> */}
            </InputGroup>

            {/* <Row>
                <Tooltip placement="right" isOpen={tooltip} target="TooltipJoin" toggle={tooltipToggle}>
                    Click the info icon for server details.
                </Tooltip>
                <Col  xl="2" md="2" className={(!darkMode) ? ("serverHeaderMain d-none d-md-block") : ("serverHeaderMain_dark d-none d-md-block")} id="TooltipJoin">Join</Col>
                <Col  xl="1" md="1" xs="4" sm="4" className={(!darkMode) ? ("serverHeaderMain") : ("serverHeaderMain_dark")}>Players</Col>
                <Col  xl="4" md="5" xs="8" sm="8" className={(!darkMode) ? ("serverHeaderMain") : ("serverHeaderMain_dark")}>Host</Col>
                <Col  xl="2" md="2" className={(!darkMode) ? ("serverHeaderMain d-none d-md-block") : ("serverHeaderMain_dark d-none d-md-block")}>Mission Area</Col>
                <Col  xl="2" md="2" className={(!darkMode) ? ("serverHeaderMain d-none d-md-block") : ("serverHeaderMain_dark d-none d-md-block")}>Mode</Col>
                <Col  xl="1" className={(!darkMode) ? ("serverHeaderMain d-none d-xl-block") : ("serverHeaderMain_dark d-none d-xl-block")}>Build</Col>
            </Row>  */}
            {foundServers && foundServers.length > 0 ? (
              <>
                <TitleCard servers={foundServers} darkMode={darkMode} />
                <br />
                <br />
              </>
            ) : ( 
              <>
                <h3>Looking for servers...</h3>
                <PacmanLoader color="grey" loading={loadinganim} cssOverride={override} size={35} />
              </>
            )}
               
            {/* {foundServers && foundServers.length > 0 ? (
              <>
              </>
            ) : (
              <></>
            )} */}
          
      </div>
      <TickerList />
      </>
  );
}


export default V6;